<template>
  <div class="owner">
    <div class="owner_con">
      <div class="echarts_real">
        <div class="echarts_real_date">
          <el-date-picker
            v-model="echartsDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleEchartsData"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>

        <div style="height: 450px">
          <div id="main"></div>
        </div>
      </div>

      <div class="table_con_agent">
        <el-date-picker
          v-model="echartsDate2"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="handleTableList"
          style="margin: 12px"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-table :data="tableData" border style="width: 98%">
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="startPlaceName" label="起点">
          </el-table-column>
          <el-table-column prop="endPlaceName" label="终点"> </el-table-column>
          <el-table-column prop="startTime" label="开始时间"> </el-table-column>
        </el-table>

        <Pagination
          v-show="pageLength >= 0"
          :total="pageLength"
          :limit.sync="pageSize"
          @pagination="handlePageChange"
          :page-sizes="pageSizes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";

import { reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import Pagination from "@/components/Pagination";

import { format_time_date, show_date_format } from "@/util/funBusiness";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 获取时间选择器的月份信息
          const timeyear = time.getFullYear(); // 获取时间选择器的年份
          let timemonth = time.getMonth() + 1; // 获取时间选择器的月份
          let timeday = time.getDate(); // 获取时间选择器的月份
          if (timemonth >= 1 && timemonth < 10) {
            timemonth = "0" + timemonth;
          }
          if (timeday >= 1 && timeday < 10) {
            timeday = "0" + timeday;
          }
          const elTimeData =
            timeyear.toString() + timemonth.toString() + timeday.toString();
          //小于指定时间都不可选
          return elTimeData < 20230215 || time.getTime() > new Date().getTime();
        },
      },

      echartsDate: [],
      form: {
        beginDate: "",
        endDate: "",
      },
      echartsDate2: [],

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  created() {
    this.getDefaultTime();
  },

  mounted() {
    const opt = {
      url: reqUrlAgent.AgentgetTravelDriverCount,
      method: "POST",
      params: JSON.stringify({
        startTime: this.echartsDate[0],
        endTime: this.echartsDate[1],
        province: localStorage.getItem("province"),
        city: localStorage.getItem("city") || "",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          var chartDom = document.getElementById("main");

          let timeData = show_date_format(
            this.form.beginDate,
            this.form.endDate
          );

          const option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                animation: false,
              },
            },
            legend: {
              data: ["车主行程统计"],
              left: 10,
            },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: "none",
                },
                restore: {},
                saveAsImage: {},
              },
            },
            axisPointer: {
              link: [
                {
                  xAxisIndex: "all",
                },
              ],
            },

            grid: [
              {
                left: 60,
                right: 50,
                bottom: "50",
                height: "70%",
              },
            ],
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                axisLine: { onZero: true },
                data: timeData,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "车主行程统计",
                type: "line",
                symbolSize: 8,
                // prettier-ignore
                data: res.data.data,
              },
            ],
          };

          var myChart = echarts.init(chartDom);
          myChart.setOption(option);
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);

    showLoading();
    const optList = {
      url: reqUrlAgent.AgentgetTravelDriverDetail,
      method: "POST",
      params: JSON.stringify({
        startTime: this.echartsDate2[0],
        endTime: this.echartsDate2[1],
        province: localStorage.getItem("province"),
        city: localStorage.getItem("city") || "",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          if (res.data.data.list) {
            res.data.data.list.map((item) => {
              item.startTime = format_time_date(item.startTime);
            });
          }
          hideLoading();
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optList);
  },

  methods: {
    handleEchartsData(e) {
      this.form.beginDate = e[0];
      this.form.endDate = e[1];

      showLoading();
      const opt = {
        url: reqUrlAgent.AgentgetTravelDriverCount,
        method: "POST",
        params: JSON.stringify({
          startTime: this.echartsDate[0],
          endTime: this.echartsDate[1],
          province: localStorage.getItem("province"),
          city: localStorage.getItem("city") || "",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            var chartDom = document.getElementById("main");
            let timeData = show_date_format(e[0], e[1]);

            const option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  animation: false,
                },
              },
              legend: {
                data: ["车主行程统计"],
                left: 10,
              },
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: "none",
                  },
                  restore: {},
                  saveAsImage: {},
                },
              },
              axisPointer: {
                link: [
                  {
                    xAxisIndex: "all",
                  },
                ],
              },

              grid: [
                {
                  left: 60,
                  right: 50,
                  bottom: "100",
                  height: "70%",
                },
              ],
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  axisLine: { onZero: true },
                  data: timeData,
                },
              ],
              yAxis: [
                {
                  type: "value",
                  max: 100,
                },
              ],
              series: [
                {
                  name: "车主行程统计",
                  type: "line",
                  symbolSize: 8,
                  // prettier-ignore
                  data: res.data.data,
                },
              ],
            };

            var myChart = echarts.init(chartDom);
            myChart.setOption(option);

            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleTableList() {
      showLoading();
      const optList = {
        url: reqUrlAgent.AgentgetTravelDriverDetail,
        method: "POST",
        params: JSON.stringify({
          startTime: this.echartsDate2[0],
          endTime: this.echartsDate2[1],
          province: localStorage.getItem("province"),
          city: localStorage.getItem("city") || "",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            if (res.data.data.list) {
              res.data.data.list.map((item) => {
                item.startTime = format_time_date(item.startTime);
              });
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(optList);
    },

    getDefaultTime() {
      const start = `${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`;
      const end = `${dayjs().subtract(0, "day").format("YYYY-MM-DD")}`;
      this.echartsDate = [start, end];
      const start2 = `${dayjs().subtract(1, "day").format("YYYY-MM-DD")}`;
      this.echartsDate2 = [start2, end];
      this.form.beginDate = start;
      this.form.endDate = end;
    },

    handlePageChange(data) {
      showLoading();
      const optList = {
        url: reqUrlAgent.AgentgetTravelDriverDetail,
        method: "POST",
        params: JSON.stringify({
          startTime: this.echartsDate2[0],
          endTime: this.echartsDate2[1],
          province: localStorage.getItem("province"),
          city: localStorage.getItem("city") || "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            if (res.data.data.list) {
              res.data.data.list.map((item) => {
                item.startTime = format_time_date(item.startTime);
              });
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(optList);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 400;
}
.owner {
  width: 100%;
  z-index: 1;

  .owner_con {
    width: 100%;
    height: calc(100% - 100px);
    margin-top: 20px;

    .echarts_real {
      width: 100%;
      height: 550px;
      background: #fff;
      border-radius: 24px;

      .echarts_real_date {
        display: flex;
        align-items: center;
        padding: 12px 10px;
      }

      #main {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .table_con_agent {
      width: 100%;
      background: #fff;
      border-radius: 24px;
      margin-top: 20px;

      .el-table {
        border-radius: 8px;
        margin: 10px auto;
        overflow-y: scroll;
      }
    }
  }
}
</style>
